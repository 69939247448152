import React, { useState } from 'react';
import { Input, Button } from 'ui';
import { Formik, Form } from 'formik';
import { sendFeedback } from 'api/requests';
import * as Yup from 'yup';
import { getI18n, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const FeedbackForm = (props) => {
  const { t } = useTranslation('contact');
  const i18n = getI18n();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [issubmitted, setIsSubmitted] = useState(false);

  const location = useLocation();
  const formId = props.formId || location.pathname;

  if (!formId) {
    console.error('The "formId" parameter is missing. ');
  }

  const locale = props.locale || i18n.resolvedLanguage;

  if (!locale) {
    console.error('The "locale" parameter is missing. ');
  }

  const initialValues = {
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: '',
    receiver: props?.receiver || '',
    to: props?.to || null
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3, t('more_than_3_chars', { ns: 'validation' })).required(t('required', { ns: 'validation' })),
    email: Yup.string().email(t('invalid_email', { ns: 'validation' })).required(t('required', { ns: 'validation' })),
    subject: Yup.string().required(t('required', { ns: 'validation' })),
    phone: Yup.string().matches(/^(\+|[0-9])[0-9 -]+[0-9]$/, t('invalid_phone', { ns: 'validation' })).required(t('required', { ns: 'validation' })),
    message: Yup.string().min(3, t('more_than_3_chars', { ns: 'validation' }))
  });

  const handleFormBlur = (event, values, handleBlur) => {
    handleBlur(event);
  };

  const saveChanges = async (event, values, { resetForm }) => {
    values.form_id = formId;
    values.locale = locale;

    setIsSubmitting(true);
    const response = await sendFeedback(values);

    if (response.status === 201) {
      resetForm();
      setIsSubmitted(true);
      setIsSubmitting(false);
    }
  };

  return (issubmitted
    ? <div className="feedback-form__success">
      <p>{t('text.submitted')}</p>
    </div>
    : <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) =>
        saveChanges(null, values, { resetForm })
      }
    >
      {({ touched, errors, handleBlur, values, handleChange }) => {
        return (
          <Form className={`feedback-form${props?.className ? ` ${props.className}` : ''}`}>
            <Input
              handleChange={handleChange}
              onBlur={(e) => handleFormBlur(e, values, handleBlur)}
              touched={touched}
              errors={errors}
              values={values}
              type="text"
              name="name"
              label={t('field.name')}
              disabled={isSubmitting}
            />
            <Input
              handleChange={handleChange}
              onBlur={(e) => handleFormBlur(e, values, handleBlur)}
              touched={touched}
              errors={errors}
              values={values}
              type="email"
              name="email"
              label={t('field.email')}
              disabled={isSubmitting}
            />
            <Input
              handleChange={handleChange}
              onBlur={(e) => handleFormBlur(e, values, handleBlur)}
              touched={touched}
              errors={errors}
              values={values}
              type="text"
              name="phone"
              label={t('field.phone')}
              disabled={isSubmitting}
            />
            {props?.receiver && <div className="field-wrap">
              <select
                id="receiver"
                name="receiver"
                value={values.receiver}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
              >
                <option value="parts">{t('option.parts')}</option>
                <option value="service">{t('option.service')}</option>
              </select>
              <label htmlFor="receiver">{t('field.receiver')}</label>
            </div>}
            <Input
              handleChange={handleChange}
              onBlur={(e) => handleFormBlur(e, values, handleBlur)}
              touched={touched}
              errors={errors}
              values={values}
              type="text"
              name="subject"
              label={t('field.subject')}
              disabled={isSubmitting}
            />
            <Input
              as="textarea"
              handleChange={handleChange}
              onBlur={(e) => handleFormBlur(e, values, handleBlur)}
              touched={touched}
              errors={errors}
              values={values}
              type="text"
              name="message"
              label={t('field.message')}
              disabled={isSubmitting}
            />
            <Button
              className="user-modal__save"
              actionName="save"
              text={t('button.submit')}
              type="submit"
              disabled={isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FeedbackForm;
